import React from "react";
import {
  Icon,
  Markdown,
  Pressable,
  ScreenContainer,
  WebView,
  withTheme,
} from "@draftbit/ui";
import { Platform, ScrollView, Text, View } from "react-native";
import * as GlobalStyles from "../GlobalStyles.js";
import * as GlobalVariables from "../config/GlobalVariableContext";
import * as webView from "../custom-files/webView";
import palettes from "../themes/palettes";
import * as Utils from "../utils";
import Breakpoints from "../utils/Breakpoints";
import * as StyleSheet from "../utils/StyleSheet";
import imageSource from "../utils/imageSource";
import useWindowDimensions from "../utils/useWindowDimensions";

const TermsAndConditionsScreen = (props) => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;

  return (
    <ScreenContainer hasSafeArea={false} scrollable={false}>
      {/* Header */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: "center",
            flexDirection: "row",
            justifyContent: "space-between",
          },
          dimensions.width
        )}
      >
        <>
          {Platform.OS === "web" ? null : (
            <View
              style={StyleSheet.applyWidth({ height: 48 }, dimensions.width)}
            />
          )}
        </>
        <Text
          accessible={true}
          selectable={false}
          {...GlobalStyles.TextStyles(theme)["Text"].props}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.TextStyles(theme)["Text"].style, {
              flex: 1,
              fontFamily: "BaiJamjuree_600SemiBold",
              fontSize: 21,
              marginLeft: 48,
              textAlign: "center",
            }),
            dimensions.width
          )}
        >
          {"Terms and Conditions"}
        </Text>
        {/* close */}
        <Pressable
          onPress={() => {
            try {
              navigation.goBack();
            } catch (err) {
              console.error(err);
            }
          }}
          activeOpacity={0.3}
        >
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: "center",
                height: 48,
                justifyContent: "center",
                width: 48,
              },
              dimensions.width
            )}
          >
            <Icon
              size={24}
              color={palettes.App["App Theme"]}
              name={"AntDesign/closecircleo"}
            />
          </View>
        </Pressable>
      </View>
      {/* Web Preview */}
      <View style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}>
        <Utils.CustomCodeErrorBoundary>
          <webView.WebViewComponent url={Constants.TERMS_CONDITIONS} />
        </Utils.CustomCodeErrorBoundary>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(TermsAndConditionsScreen);
